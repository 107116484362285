import React, { CSSProperties, ReactNode } from 'react'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom';

export type ButtonType = 'primary' | 'secondary'

export const LineButton = (props: {
	style?: CSSProperties;
	className?: string;
	children?: ReactNode;
	url?: string;
	blankPage?: boolean;
	onClick?: () => void;
	type?: ButtonType;
	disabled?: boolean;
	small?: boolean;
	sessionId?: string | null;
}): React.ReactElement => {

	const { 
		style, children, url, onClick, type = 'primary', disabled, 
		className, small = false, sessionId = null, blankPage = false 
	} = props

	const history = useHistory()

	const openUrl = () => {
		if (url)
			if (blankPage) {
				window.open(url, "_blank")
			} else {
				history.push(url)
			}
		if (onClick)
			onClick()
	}

	return (
		<button
			className={classNames(
				type, className, "line-button"
			)}
			style={{
				...style,
			}}
			onClick={openUrl}
			disabled={disabled || sessionId != null}
		>
			<span className={classNames(
				"label", "text-action", {
					"footnote": small,
					"text-medium": !small
				}
			)}>{sessionId ? 'Processing..' : children}</span>
			<span className="icon">
				<span></span>
			</span>
			<span className="icon2"></span>
		</button>
	)
};
