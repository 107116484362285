import React from 'react'
import { Container } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

import PartnerHeaderSection from '../sections/Partners/PartnerHeaderSection'
import FooterSection from '../sections/Main/FooterSection'
import PartnerSection from 'views/sections/Partners/PartnerSection'

const Partners = () => {

	return (
		<>
			<Container fluid>

				<PartnerHeaderSection 
					title="KROGAN PARTNERS" 
					subtitle="KROGANVERSE IS NOW OPEN"
					leftTitle="Why you should partner with us?"
					leftSubtitle="We choose a selected number of NFT projects to be part of our Kroganverse and game LORE. By partnering with us, you have the option to choose the evolution story of your species. In case your project is not featuring characters, then we adapt the story based on the project. NFTs can benefit from our dynamic attributes system, your project can benefit from exposure and utility and players get a wider range of NFTs to choose from and play."
					rightTitle="How partnerships work?"
					rightSubtitle="Every project integration is unique. Character projects will have a couple of tags assigned. Based on the tags, players that choose an NFT from that project, will get additional benefits over our Krogan Spaceships in the game. This makes it valuable for players to choose their crew leader."
					url="https://forms.gle/77TU9JgvwrdSMgfHA"
				/>

				<PartnerSection alt={false} />
				
				<FooterSection alt={true} />

			</Container>
		</>
	)
}

export default withRouter(Partners);