import React from 'react'
import classNames from "classnames";
import { Col, Row } from 'react-bootstrap';
import { Button, InputGroup, FormControl } from 'react-bootstrap'

import telegram from 'images/social/telegram.png'
import medium from 'images/social/medium.png'
import twitter from 'images/social/twitter.png'
import discord from 'images/social/discord.png'
import instagram from 'images/social/instagram.png'
import reddit from 'images/social/reddit.png'
import github from 'images/social/github.png'

const FooterSection = ({
    alt,
}: {
    alt: boolean;
}): React.ReactElement => {

	return (
		<Row className={classNames({
			section: true, 
			center: true,
			alt: alt
		})}>
			<Col>
				<h2 className="mb-4">Stay up to date!</h2>
				<p className="mb-2">Subscribe to our newsletter</p>
				
				<form action="https://krogancoin.us1.list-manage.com/subscribe/post?u=6b7b93a7eedf7693ad0385f1f&amp;id=c8f646def9" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
					<InputGroup className="mb-3">
						<FormControl
							id="mce-EMAIL"
							type="email"
							name="EMAIL"
							placeholder="Email"
							aria-label="Email"
							aria-describedby="basic-addon2"
						/>
						<i></i>									
						<Button type="submit" variant="outline-secondary" className="text-action">Subscribe</Button>
					</InputGroup>
				</form>

				<p className="mb-2">Follow our journey</p>
				<div className="social mb-4">
					<a href="https://t.me/krogancoin" target="_blank" rel="noreferrer"><img src={telegram} alt="Krogan Telegram" /></a>
					<a href="https://discord.gg/9JvyyaYyTZ" target="_blank" rel="noreferrer"><img src={discord} alt="Krogan Discord" /></a>
					<a href="https://krogancoin.medium.com" target="_blank" rel="noreferrer"><img src={medium} alt="Krogan Medium" /></a>
					<a href="https://twitter.com/KroganCoin" target="_blank" rel="noreferrer"><img src={twitter} alt="Krogan Twitter" /></a>
					{/* <a href="https://instagram.com/krogan.metaverse" target="_blank" rel="noreferrer"><img src={instagram} alt="Krogan Instagram" /></a> */}
					{/* <a href="https://www.reddit.com/r/KroganCoin/" target="_blank" rel="noreferrer"><img src={reddit} alt="Krogan Reddit" /></a> */}
					{/* <a href="#" target="_blank" rel="noreferrer"><img src={github} alt="Krogan Github ( Coming soon )" /></a> */}
				</div>

				<iframe src='https://egld.community/api/products/131cb020-5e98-426f-9d2b-55771c62c898/upvotes/embed' className="egld-community"></iframe>

				<p className="mb-2">@2023 Krogan. All Rights Reserved.</p>
			</Col>
		</Row>
	);
}

export default FooterSection;