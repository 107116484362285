import React from 'react'
import { Container } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

import FooterSection from '../sections/Main/FooterSection'
import HeaderSection from 'views/sections/Partners/PartnerHeaderSection'
import DiscordSection from 'views/sections/Discord/DiscordSection'

const Discord = () => {

	return (
		<>
			<Container fluid>

				<HeaderSection
					title="KROGAN DISCORD BOT" 
					subtitle="Receive alerts and information from all Krogan products"
					leftTitle="Notifications from Elrond NFT Swap"
					leftSubtitle="Only relevant notifications to your sales / offers."
					rightTitle="Notification from the game."
					rightSubtitle="Your spaceships are in danger? We'll let you know!"
				/>

				<DiscordSection />
				<FooterSection alt={true} />

			</Container>
		</>
	)
}

export default withRouter(Discord);