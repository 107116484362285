const MINUTE_IN_SECONDS = 60
const HOUR_IN_SECONDS = 3600
const DAY_IN_SECONDS = 86400
const MONTH_IN_SECONDS = 2629800
const YEAR_IN_SECONDS = 31557600

/**
 * Format number of seconds into year, month, day, hour, minute, seconds
 *
 * @param seconds
 */
const getTimePeriods = (seconds: number) => {
	let delta = Math.abs(seconds)
	const timeLeft = {
		years: 0,
		months: 0,
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
	}

	if (delta >= YEAR_IN_SECONDS) {
		timeLeft.years = Math.floor(delta / YEAR_IN_SECONDS)
		delta -= timeLeft.years * YEAR_IN_SECONDS
	}

	if (delta >= MONTH_IN_SECONDS) {
		timeLeft.months = Math.floor(delta / MONTH_IN_SECONDS)
		delta -= timeLeft.months * MONTH_IN_SECONDS
	}

	if (delta >= DAY_IN_SECONDS) {
		timeLeft.days = Math.floor(delta / DAY_IN_SECONDS)
		delta -= timeLeft.days * DAY_IN_SECONDS
	}

	if (delta >= HOUR_IN_SECONDS) {
		timeLeft.hours = Math.floor(delta / HOUR_IN_SECONDS)
		delta -= timeLeft.hours * HOUR_IN_SECONDS
	}

	if (delta >= MINUTE_IN_SECONDS) {
		timeLeft.minutes = Math.floor(delta / MINUTE_IN_SECONDS)
		delta -= timeLeft.minutes * MINUTE_IN_SECONDS
	}

	timeLeft.seconds = delta

	return timeLeft
}

export default getTimePeriods

export const delay = (time: number) => new Promise(resolve => setTimeout(resolve, time))

export const now = (): number => {
	return Math.round(new Date().getTime() / 1000)
}

export const timeleft = (timestamp: number): number => {
	return timestamp - now()
}

export const timestampToString = (waitingLabel: string, readyLabel: string, timestamp: number, short: boolean = true) => {
	let dif = timestamp - now()
  if (dif <= 0) return readyLabel
	if (dif < 60) {
		return `${waitingLabel} ${dif}${short ? 's' : dif == 1 ? ' second' : ' seconds'}`
	} else if (dif < 3600) {
		return `${waitingLabel} ${Math.round(dif/60)}${short ? 'm' : dif == 1 ? ' minute' : ' minutes'}`
	} else if (dif < 3600 * 24) {
		return `${waitingLabel} ${Math.round(dif/3600)}${short ? 'h' : dif == 1 ? ' hour' : ' hours'}`
	} else {
		return `${waitingLabel} ${Math.round(dif/(3600*24))}${short ? 'd' : dif == 1 ? ' day' : ' days'}`
	}
}