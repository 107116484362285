import React from 'react';
import { Modal } from 'react-bootstrap';

export interface VideoModalType {
	show: boolean;
	handleClose: () => void;
}
const VideoModal = ({
	show,
	handleClose,
}: VideoModalType): React.ReactElement => {
	
	return (
		<>
			<Modal
				show={show}
				onHide={handleClose}
				className="modal-container large"
				animation={true}
				centered
			>
				<Modal.Header closeButton className="btn-close-white"></Modal.Header>
				<Modal.Body className="center">
					<div className="aspect-ratio-box">
						<iframe 
							className="aspect-ratio-box-inside"
							src="https://www.youtube.com/embed/n7_FfMYFC-U?autoplay=1" 
							title="Krogan - How it all began" 
							frameBorder="0" 
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
							allowFullScreen>
						</iframe>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default VideoModal;
