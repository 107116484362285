import React from 'react'
import { Container } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import HeaderSection from 'views/sections/Main/HeaderSection'

import StakingHeaderSection from 'views/sections/Staking/StakingHeader'
import FooterSection from '../sections/Main/FooterSection'

const Rarity = () => {

	return (
		<>
			<Container fluid>
				<HeaderSection title='Staking' subtitle='Earn rewards for holding your favourite token'>
					<StakingHeaderSection />
				</HeaderSection>

				<FooterSection alt={true} />
			</Container>
		</>
	)
}

export default withRouter(Rarity);