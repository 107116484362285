const MINUTE_IN_SECONDS = 60
const HOUR_IN_SECONDS = 3600
const DAY_IN_SECONDS = 86400
const MONTH_IN_SECONDS = 2629800

/**
 * Format number of seconds into year, month, day, hour, minute, seconds
 *
 * @param seconds
 */
export const getTimePeriods = (seconds: number) => {
  let delta = Math.abs(seconds)
  const timeLeft = {
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  }

  if (delta >= MONTH_IN_SECONDS) {
    timeLeft.months = Math.floor(delta / MONTH_IN_SECONDS)
    delta -= timeLeft.months * MONTH_IN_SECONDS
  }

  if (delta >= DAY_IN_SECONDS) {
    timeLeft.days = Math.floor(delta / DAY_IN_SECONDS)
    delta -= timeLeft.days * DAY_IN_SECONDS
  }

  if (delta >= HOUR_IN_SECONDS) {
    timeLeft.hours = Math.floor(delta / HOUR_IN_SECONDS)
    delta -= timeLeft.hours * HOUR_IN_SECONDS
  }

  if (delta >= MINUTE_IN_SECONDS) {
    timeLeft.minutes = Math.floor(delta / MINUTE_IN_SECONDS)
    delta -= timeLeft.minutes * MINUTE_IN_SECONDS
  }

  timeLeft.seconds = Math.round(delta)

  return timeLeft
}

export const now = () => Math.round(Date.now() / 1000)

export const timeLeftToShortString = (seconds: number) => {
  if (seconds < 0) return '0s'
  const periods = getTimePeriods(seconds)

  if (periods.months > 0) {
    return `${periods.months}M ${periods.days}D`
  }
  if (periods.days > 0) {
    return `${periods.days}D ${periods.hours}H`
  }
  if (periods.hours > 0) {
    return `${periods.hours}H ${periods.minutes}m`
  }
  if (periods.minutes > 0) {
    return `${periods.minutes}m ${periods.seconds}s`
  }
  return `${periods.seconds}s`
}

export const expiringIn = (timestamp: number, short: boolean = true, past: boolean = false) => {
  let dif = timestamp - now()
  if (past && dif < 0) dif *= -1
  if (dif < 0) {
    return 'Expired'
  } else if (dif < 60) {
    return `${dif}${short ? 's' : dif == 1 ? ' second' : ' seconds'}`
  } else if (dif < 3600) {
    return `${Math.round(dif / 60)}${short ? 'm' : dif == 1 ? ' minute' : ' minutes'}`
  } else if (dif < 3600 * 24) {
    return `${Math.round(dif / 3600)}${short ? 'h' : dif == 1 ? ' hour' : ' hours'}`
  } else {
    return `${Math.round(dif / (3600 * 24))}${short ? 'd' : dif == 1 ? ' day' : ' days'}`
  }
}