import React from 'react'
import classNames from 'classnames'
import { useCoreContext, useCoreDispatch } from 'core'
import { LineButton } from '../uielements/LineButton'

import connect from 'images/connect.svg'

const TransactionButton = ({
	onClick,
	className,
	children,
}: {
	onClick: () => void;
	className?: string;
	children: React.ReactNode
}): React.ReactElement => {

	const { address } = useCoreContext()
	const dispatch = useCoreDispatch()

	const clickAction = () => {
		if (address && address != '') {
			onClick()
		} else {
			dispatch({ type: 'setShowLoginModal', showLoginModal: true })
		}
	}

	let externalDiv = classNames(className, {
		'display-inline': true,
	})

	return (
		<div className={externalDiv} onClick={clickAction}>
			{address ? 
				children
			: (
				<LineButton className="w-100">
					<img src={connect} className="me-2 img-fluid" height="18" alt="Connect wallet to Krogan" />
					CONNECT
				</LineButton>
			)}
		</div>
	);
};

export default TransactionButton;
