import React from 'react'
import { Container } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

import MainSection from '../sections/Main/MainSection'
import PlatformSection from '../sections/Main/PlatformSection'
import MintSection from '../sections/Main/MintSection'
import DetailsSection from '../sections/Main/DetailsSection'
import RoadmapSection from '../sections/Main/RoadmapSection'
import CtaSection from '../sections/Main/CtaSection'
import FooterSection from '../sections/Main/FooterSection'

const Main = () => {

	return (
		<>
			<Container fluid>

				<MainSection />
				<MintSection alt={false} />
				<PlatformSection alt={true} />

				<DetailsSection
					titleLeft="Economy"
					descriptionLeft="Our team has a big focus on the mathematical point of view of the economy. Analysed inside-out to have a balanced potential for everybody, while protecting Krogan token from inflation, one of the issues a lot of game tokens are facing."
					titleRight="Marketplace"
					descriptionRight="KroganSwap.com, the space that ultimately will serve as a shop for in-game items when the game is ready. 
					Currently it started by being a place where NFTs from the entire Elrond Network can be traded. This allows us to reward the community with 30% of the Marketplace earnings, in order to provide more passive income."
				/>

				<RoadmapSection alt={false} />
				<CtaSection 
					id="discord"
					alt={true}
					title="Are you on Discord?"
					body="Insights and regular updates will be published on our Discord, together with opportunities that early participants can get on. Come and say hi!"
					cta="JOIN OUR DISCORD"
					link="https://discord.gg/9JvyyaYyTZ" />

				{/* <DetailsSection
					titleLeft="The game"
					descriptionLeft="The multiplayer game focuses on social activities, team work and decisions, mining, crafting and of course a smart battling system. Trading materials and items within the community will have a huge factor in generating income and improving your performance in the game."
					titleRight="Economy"
					descriptionRight="Our team has a big focus on the mathematical point of view of the economy. Analysed inside-out to have a balanced potential for everybody, while protecting Krogan token from inflation, one of the issues a lot of game tokens are facing."
				/>

				<CtaSection 
					id="team"
					alt={false}
					title="Meet the team"
					body="A group of ambitious people based in Amsterdam 🇳🇱"
					cta="FIND OUT MORE"
					link="https://whitepaper.krogancoin.com/krogan-team" /> */}

				<FooterSection alt={false} />

			</Container>
		</>
	)
}

export default withRouter(Main);