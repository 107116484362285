import React from "react";

const useAnalyticsEventTracker = (category: string) => {
	const eventTracker = (action: string, label?: string) => {
		gtag('event', action, { 
			event_category: category, 
			event_label: label 
		});
	}
	return eventTracker;
}
export default useAnalyticsEventTracker;