import React from 'react'
import { Col, Row } from 'react-bootstrap';

const DetailsSection = ({
    titleLeft,
	descriptionLeft,
	titleRight,
	descriptionRight
}: {
    titleLeft: string;
	descriptionLeft: string;
	titleRight: string;
	descriptionRight: string;
}): React.ReactElement => {
	return (
		<Row>
			<Col md={6} className="dark">

				<div className="left-section mt-5 mb-4">
					<div className="box">
						<h3>{titleLeft}</h3>
						<p>{descriptionLeft}</p>
					</div>
				</div>

			</Col>
			<Col md={6} className="right-side">
				<h2 id="background-text">
					Krogan
				</h2>

				<div className="right-section mt-5">
					<div className="box">
						<h3>{titleRight}</h3>
						<p>{descriptionRight}</p>
					</div>
				</div>
			</Col>
		</Row>
	);
}

export default DetailsSection;