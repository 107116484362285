
/**
 * Cache an object into local storage
 * @param key key
 * @param item object
 * @param ttl cache time in seconds
 */
export const setItem = (key: string, item: any, ttl: number = 3600) => {
	const expires = Date.now() / 1000 + ttl;
	localStorage.setItem(
		key,
		JSON.stringify({
			expires,
			data: item,
		})
	);
};

export const getItem = (key: string): any => {
	const item = localStorage.getItem(key);
	if (!item) {
		return null;
	}

	const deserializedItem = JSON.parse(item);
	if (!deserializedItem) {
		return null;
	}

	if (!Object.prototype.hasOwnProperty.call(deserializedItem, 'expires') || !Object.prototype.hasOwnProperty.call(deserializedItem, 'data')) {
		return null;
	}

	const expired = Date.now() / 1000 >= deserializedItem.expires;
	if (expired) {
		localStorage.removeItem(key);
		return null;
	}

	return deserializedItem.data;
};

export const removeItem = (key: string) => localStorage.removeItem(key);
