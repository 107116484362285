import { kroTicker, network } from 'config';
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { accountService } from 'services/account';
import { StakingToken } from 'utils/types';
import StakingBoxView from 'views/components/Tokens/StakingBox';
import StakingModal, { StakingActionType } from './StakingModal';

const StakingHeaderSection = (): React.ReactElement => {

	const [price, setPrice] = useState(0);
	const [showStakingModal, setShowStakingModal] = useState(false)
	const [stakingAction, setStakingAction] = useState(StakingActionType.STAKE)
	const [stakingToken, setStakingToken] = useState(undefined as StakingToken | undefined)

	useEffect(() => {
		loadData()
	}, [])

	const loadData = () => {
		accountService.tokenPrice(kroTicker).then(price => {
			setPrice(price)
		})
	}

	return (
		<>
		<Row>
			{network.id == 'mainnet' ?
			<>
				<Col md={6} lg={{ span: 4, offset: 2 }} className='mb-3'>
					<StakingBoxView 
						title="Basic"
						info={<p><strong className="colored">3 days cooldown!<br/></strong> If you withdraw quicker than 3 days, <strong>a 1% penalty will be applied</strong>. Any action will reset the timer!</p>}
						stakingToken={{
							ticker: "KRO",
							tokenId: "KRO-df97ec",
							price: price,
							poolAddress: "erd1qqqqqqqqqqqqqpgq77qp09xl770vrnse3u39ty4qmxvuap5dwe0sgq9rpn",
							owner: "erd1amtjw4s4j60ucl9fjeu4x5elhdlhml4654uhg77ll4p93u4afa8s9lsrwq"
						}}
						onAction={(obj) => {
							setStakingAction(obj.action)
							setStakingToken(obj.token)
							setShowStakingModal(true)
						}}
					/>
				</Col>

				<Col md={6} lg={4} className='mb-3'>	
					<StakingBoxView 
						title="Extreme"
						tag="Finished"
						finished
						info={<p><strong className="colored">0% penalty for withdrawing!</strong></p>}
						stakingToken={{
							ticker: "KRO",
							tokenId: "KRO-df97ec",
							price: price,
							poolAddress: "erd1qqqqqqqqqqqqqpgqj0hx6dcj585ag6fk5ahxljxyzga0ukmwwe0sy48ux0",
							owner: "erd1amtjw4s4j60ucl9fjeu4x5elhdlhml4654uhg77ll4p93u4afa8s9lsrwq"
						}}
						onAction={(obj) => {
							setStakingAction(obj.action)
							setStakingToken(obj.token)
							setShowStakingModal(true)
						}}
					/>
				</Col>
			</>
			:
				<Col lg={4} className='mb-3'>	
					<StakingBoxView 
						title="Basic"
						tag="DEVNET"
						info={<p><strong className="colored">3 days cooldown!<br/></strong> If you withdraw quicker than 3 days, <strong>a 1% penalty will be applied</strong>. Any action will reset the timer!</p>}
						stakingToken={{
							ticker: "KRO",
							tokenId: "KRO-878dce",
							price: 0,
							poolAddress: "erd1qqqqqqqqqqqqqpgqmht5yt5cknjdcqas8xhg22x5ds78f8faxqtsv4s8sf",
							owner: "erd1amtjw4s4j60ucl9fjeu4x5elhdlhml4654uhg77ll4p93u4afa8s9lsrwq"
						}}
						onAction={(obj) => {
							setStakingAction(obj.action)
							setStakingToken(obj.token)
							setShowStakingModal(true)
						}}
					/>
				</Col>
			}

		</Row>
		<StakingModal 
			show={showStakingModal} 
			action={stakingAction} 
			stakingToken={stakingToken} 
			handleClose={() => setShowStakingModal(false)} 
			reload={loadData} 
		/>
		</>
	)
}

export default StakingHeaderSection;