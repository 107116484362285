import React from 'react'

import { Container } from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

import Exchange from 'views/components/Others/Exchange'
import HeaderSection from 'views/sections/Main/HeaderSection'
import FooterSection from '../sections/Main/FooterSection'

const Swap = () => {

	return (
		<>
			<Container fluid>
				<HeaderSection title='Krogan Swap' subtitle='Swap tokens from Krogan World'>
					<Exchange />
				</HeaderSection>
				<FooterSection alt={true} />
			</Container>
		</>
	)
}

export default withRouter(Swap);