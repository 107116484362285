import React from 'react'
import classNames from "classnames";
import { Col, Row } from 'react-bootstrap';
import { LineButton } from 'views/components/uielements/LineButton';

const CtaSection = ({
	id,
    alt,
	title,
	body,
	cta,
	link,
}: {
	id: string;
    alt: boolean;
	title: string;
	body: string;
	cta: string;
	link: string;
}): React.ReactElement => {

	const openInNewTab = (url: string) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
		if (newWindow) newWindow.opener = null
	}

	return (
		<Row id={id} className={classNames({
			section: true, 
			center: true,
			alt: alt
		})}>
			<Col>
				<h2 className="mb-4">{title}</h2>
				<p className="block" dangerouslySetInnerHTML={{__html: body}}></p>
				<LineButton type="secondary" className="mt-4" onClick={() => { openInNewTab(link) }}>{cta}</LineButton>
			</Col>
		</Row>
	);
}

export default CtaSection;