import React from 'react'
import classNames from "classnames";
import { Col, Row } from 'react-bootstrap';

import sectionBgRight from 'images/section-background-right.png'
import sideImage from 'images/side-image.png'

const PlatformSection = ({
    alt,
}: {
    alt: boolean;
}): React.ReactElement => {
	return (
		<Row id="features" className={classNames({
			section: true, 
			"no-padding": true,
			alt: alt
		})}>
			<Col md={6} className="section-background-left">
				<div className="with-padding demo">
					<img src={sideImage} className="valign-middle, noselect" alt="Krogan NFTs" />
				</div>
			</Col>
			<Col md={6} className="with-padding">
				<h2 className="mb-4">One Game To Rule Them All</h2>
				<p className="text-strong mb-2">Imagine a space exploration game that allows you to:</p>
				<ul>
					<li className="colored">Mine for resources</li>
					<li className="colored">Build companies</li>
					<li className="colored">Become a faction leader</li>
					<li className="colored">Lead interstellar wars</li>
					<li className="colored">Buy and sell ingame resources</li>
					<li className="colored">Colonise planets</li>
				</ul>
				<br />
				<p>
					The multiplayer game focuses on social activities, team work and decisions, mining, crafting and of course a smart battling system. 
					Trading materials and items within the community will have a huge factor in generating income and improving your performance in the game.
					Using high quality pixel art, the game will have a pleasing retro feel combined with futuristic views of the interstellar civilizations.
				</p>
				<img src={sectionBgRight} className="block mt-4 noselect" style={{ maxHeight: 175 }} alt="Krogan NFTs" />
			</Col>
		</Row>
	);
}

export default PlatformSection;