import React, { useState } from 'react';

export interface ReadMoreType {
	content: React.ReactNode;
	readMore?: React.ReactNode;
}
const ReadMore = ({
	content,
	readMore,
}: ReadMoreType): React.ReactElement => {
		
	const [visible, setVisible] = useState(false);

	return (
		<div className="long-text">
			{content}
			{visible && readMore}

			{readMore && <button onClick={() => setVisible(!visible)} className="center-button small mt-2">{visible ? 'Read less' : 'Read more'}</button>}
		</div>
	);
};

export default ReadMore;
