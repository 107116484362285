import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { kroganApi, kroTicker, network } from 'config';
import { BrowserRouter as Router } from 'react-router-dom';
import { CoreContextProvider } from 'core';
import { DappProvider } from '@multiversx/sdk-dapp/wrappers';
import { TransactionsToastList, SignTransactionsModals } from "@multiversx/sdk-dapp/UI";


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <CoreContextProvider api={kroganApi} kroTicker={kroTicker}>
          <DappProvider environment={network.id} customNetworkConfig={network}>
              <TransactionsToastList title="Toasts" successfulToastLifetime={5000} className="" />
              <SignTransactionsModals />
              <App />
          </DappProvider>
      </CoreContextProvider>       
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
