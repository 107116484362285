import '../utils/extensions'
import { APIESDT, ESDTMetaData } from 'core'
import { kroganNFT, network } from 'config'
import { NftModel } from 'models/NftModel'

export const tokenPrice = (id: string): Promise<number> => {
	let tokenId = id == 'EGLD' ? 'WEGLD-bd4d79' : id
	return fetch('https://graph.xexchange.com/graphql', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
		},
		body: JSON.stringify({query: `{ getTokenPriceUSD(tokenID:"${tokenId}")}`})
	})
	.then(r => r.json())
	.then(r => r.data.getTokenPriceUSD)
	.catch(err => { console.log(err) })
}

export const exchangePairs = () => {
	return fetch('https://graph.xexchange.com/graphql', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json',
		},
		body: JSON.stringify({
			query: `
			{
			  pairs(offset: 0, limit: 500) {
				address
				state
				totalFeePercent
				info {
				  reserves0
				  reserves1
				  totalSupply
				}
				firstToken {
				  identifier
				  decimals
				}
				secondToken {
				  identifier
				  decimals
				}
				liquidityPoolToken {
				  identifier
				  decimals
				}
			  }
			}`
		})
	})
	.then(r => r.json())
	.catch(err => { console.log(err) })
}

const loadAttributes = (url: string): Promise<ESDTMetaData> => {
	return new Promise<ESDTMetaData>((resolve, reject) => {
		var jsonUrl = (url.substring(0, url.lastIndexOf(".")) + ".json")
		
		// faster to load than the gateway
		if (location.hostname != 'localhost') {
			jsonUrl = jsonUrl.replace('https://gateway.ipfs.io/ipfs', '')
		} else {
			jsonUrl = jsonUrl.replace('https://gateway.ipfs.io/ipfs', 'https://krogancoin.com')
		}

		fetch(jsonUrl)
			.then(res => res.json())
			.then((result: ESDTMetaData) => {
				resolve(result)
			}).catch(err => {
				reject(err)
			})
	})
}

export const nftDetails = {
	getNfts: (address: string): Promise<NftModel[]> => {
		return new Promise<NftModel[]>((resolve, reject) => {
			fetch(`${network.apiAddress}/accounts/${address}/nfts?size=1000&search=${kroganNFT}`)
				.then(res => res.json())
				.then((result: APIESDT[]) => {

					// Temp
					fetch(`${network.apiAddress}/nfts?size=1000&identifiers=${result.map(nft => nft.identifier).join(',')}`)
						.then(res => res.json())
						.then((result: APIESDT[]) => {
					
							// Load attributes
							let promisses = []
							for (let i = 0; i < result.length; i++) {
								const esdt = result[i];
								promisses.push(loadAttributes(window.atob(esdt.uris.first())))
							}

							Promise.all(promisses).then(values => {
								let updated = result.map((value, i) => {
									var esdt = value
									esdt.metadata = values[i]
									return new NftModel(esdt)
								})
								resolve(updated)
							}).catch(err => {
								reject(err)
							})
						})
				}).catch(err => {
					reject(err)
				})
		})
	},
	getCollection: (): Promise<{string: any}> => {
		return new Promise<{string: any}>((resolve, reject) => {
			fetch(`/collection.json`)
				.then(res => res.json())
				.then((result: {string: any}) => {
					resolve(result)
				}).catch(err => {
					reject(err)
				})
		})
	},
	getNft: (id: string): Promise<APIESDT> => {
		return new Promise<APIESDT>((resolve, reject) => {
			fetch(`${network.apiAddress}/nfts?identifiers=${id}`)
				.then(res => res.json())
				.then((result: APIESDT[]) => {
					
					const nft = result.first()
					if (!nft) return reject('Nft not found')

					loadAttributes(nft.url)
						.then((metadata: ESDTMetaData) => {
							nft.metadata = metadata
							resolve(nft)
						})

				}).catch(err => {
					reject(err)
				})
		})
	}
}
