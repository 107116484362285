import React from 'react';
import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import Menu, { MenuType } from 'views/components/Menu';
import { LineButton } from 'views/components/uielements/LineButton';
import { ConnectButtonContent } from 'core/components/ConnectButtonContent';

const HeaderSection = ({
	title,
	subtitle,
	children,
}: {
	title: string,
	subtitle: string,
	children: React.ReactNode;
}): React.ReactElement => {

	return (
		<div className="main">
			<Menu menuType={MenuType.Other} />

			<Row className={classNames({
				"row-header": true,
				"mb-3": true
			})}>

				<Col md={6}>
					<div className='section--header'>
						<h2 className="mb-1">{title}</h2>
						<p>{subtitle}</p>
					</div>
				</Col>
				<Col md={6} className="right-side">
					<div className="right-section">
						<LineButton type="primary" style={{ marginRight: '3rem' }}>
							<ConnectButtonContent />
						</LineButton>
					</div>
				</Col>
			</Row>

			<Col className="section">
				{children}
			</Col>
		</div>
	);
}

export default HeaderSection;