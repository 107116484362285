import React, { useEffect } from 'react';
import { Address } from '@multiversx/sdk-core/out';
import { DelegationTransactionType, ESDTToken, nominateNumberToHex, nominateStringToHex } from '../helpers';
import { b64ToBn, denominate, quickDenominate } from '../helpers/denominate/formatters';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { useCoreContext, useCoreDispatch } from 'core/context/context';
import { coreAccountService } from 'core/services/account.service';
import { useTransactions } from 'core/hooks';
import { expiringIn } from 'core/helpers/time';

export const lkkroTicker = 'LKKRO-e6ef92';

export const TokensView = (props: {
	small?: boolean;
	includeLocked?: boolean;
}): React.ReactElement => {

	const { includeLocked = true, small = false } = props

	const dispatch = useCoreDispatch()
	const { kroToken, lkkroTokens, kroTicker } = useCoreContext()
	const { address } = useGetAccountInfo()
	const { sendTransaction } = useTransactions()
	

	const unlock = (token: ESDTToken) => {
		const tokenId = nominateStringToHex(token.ticker)
		const tokenNonce = nominateNumberToHex('' + token.nonce ?? 0)
		const amount = nominateNumberToHex(token.balance)
		const receiver = new Address('erd1qqqqqqqqqqqqqpgqyq5g6uvx0hzwdl0864sxpdtquv0hhaa5we0s5hpa90').hex()
		const func = nominateStringToHex('claimTokens')

		const data = tokenId+'@'+tokenNonce+'@'+amount+'@'+receiver+'@'+func
		const txArguments = new DelegationTransactionType(address, '0', 'ESDTNFTTransfer', data)
		sendTransaction([txArguments], 'claimRewards', 'Claim Rewards', () => {})
	}

	const fetchAccount = async () => {
		if (address == '') return

		const token = await coreAccountService.fetchAccountTokens(address, kroTicker)
		dispatch({ type: 'setKroToken', token: token.first() });
		const metaTokens = await coreAccountService.fetchAccountMetaTokens(address, lkkroTicker)
		dispatch({ type: 'setLKKroTokens', tokens: metaTokens });
	};
	useEffect(() => { fetchAccount() }, [address]);

	const tokenView = (index: number, token?: ESDTToken) => {
		if (!token) return <></>

		let vesting
		if (token.attributes) {
			const timestamp = parseInt(b64ToBn(token.attributes).toString()) * 1000
			if (new Date().getTime() > timestamp) {
				vesting = ( 
				<div className="ms-3">
					<button
						className="link-button text-medium px-0"
						onClick={() => unlock(token)}
					>
						Unlock now
					</button>
				</div> 
				)
			} else {
				vesting = ( <p>&nbsp;- Vesting {expiringIn(timestamp)}</p> )
			}
		}

		return (
			<div key={index} className="d-flex align-items-center justify-content-center">
				<p className={`text-action ${small ? 'footnote' : ''}`}>
					{quickDenominate(token.balance, 0)}
				</p>
				&nbsp;
				{token.identifier == kroTicker ? 
					<img src={`/images/${kroTicker}.gif`} width={small ? 13 : 20} alt="KRO Token" /> 
				: 
					<span>{token.ticker.split('-').first()}</span>
				}
				{vesting}
			</div>
		)
	}

	return (
		<React.Fragment>
			<div className="d-flex flex-column align-items-center justify-content-center">
				{tokenView(0, kroToken)}
				{includeLocked ? lkkroTokens.map((token, i) => tokenView(i, token)) : <></>}
			</div>
		</React.Fragment>
	)
}