import React from 'react'
import { Col, Row } from 'react-bootstrap';
import arrowDown from 'images/arrow-down.png'
import Menu, { MenuType } from 'views/components/Menu';
import { ButtonAction } from 'utils/types';

import reversedTriangle from 'images/header-triangle.svg'
import { LineButton } from 'views/components/uielements/LineButton';

const PartnerHeaderSection = ({
	title,
	subtitle,
	leftTitle,
	leftSubtitle,
	rightTitle,
	rightSubtitle,
	url,
	action,
}: {
	title: string;
	subtitle: string;
	leftTitle: string;
	leftSubtitle: string;
	rightTitle: string;
	rightSubtitle: string;
	url?: string;
	action?: ButtonAction;
}): React.ReactElement => {

	const scrollToFeatures = () => {
		document.querySelector('#content')?.scrollIntoView({ 
			behavior: 'smooth' 
		});
	}

	return (
		<>
		<Row className="main">
			<Menu menuType={MenuType.Other} />
			<Col md={6} className="left-side d-flex flex-column">

				<div id="middle-side">
					<img src={reversedTriangle} alt="Krogan Minting" className="mb-4 mt-5" />
					<div className="h1">{title}</div>
					<p>{subtitle}</p>
					
					<div className="scroller">
						<a onClick={scrollToFeatures}><img src={arrowDown} alt="Learn more about Krogan Coin" /></a>
					</div>
				</div>

				<div className="left-section mb-4">
					<div className="box">
						<h3>{leftTitle}</h3>
						<p className="mb-2">{leftSubtitle}</p>

						{action &&
							<button className="side-button left mt-2 mb-2 text-action" onClick={action.action}>
								{action.title}
							</button>
						}
					</div>
				</div>

			</Col>

			<Col md={6} className="right-side d-flex flex-column justify-content-end">

				<div className="right-section second-half mb-4">
					<div className="box">
						<h3>{rightTitle}</h3>
						<p>{rightSubtitle}</p>
					</div>
				</div>
				
				{url &&
				<div className="right-section second-half">
					<LineButton className="me-5 mt-2 mb-2" type="secondary" url={url}>PROJECT APPLY</LineButton>
				</div>
				}

			</Col>
		</Row>
		</>
	);
}

export default PartnerHeaderSection;