import { getCleanApiAddress } from "@multiversx/sdk-dapp/apiCalls/utils"
import { ESDTToken } from "../helpers"

export const coreAccountService = {
	fetchAccountTokens: (address: string, query: string): Promise<ESDTToken[]> => {
		return fetch(`${getCleanApiAddress()}/accounts/${address}/tokens?search=${query}`)
			.then(res => res.json())
			.catch(err => { console.log(err) })
	},
	fetchAccountMetaTokens: (address: string, query: string): Promise<ESDTToken[]> => {
		return fetch(`${getCleanApiAddress()}/accounts/${address}/nfts?search=${query}&type=MetaESDT`)
			.then(res => res.json())
			.catch(err => { console.log(err) })
	},
	fetchAccountMultiTokens: (address: string, tokens: string[]): Promise<ESDTToken[]> => {
		return fetch(`${getCleanApiAddress()}/accounts/${address}/tokens?identifiers=${tokens.join(',')}`)
			.then(res => res.json())
			.catch(err => { console.error(err) })
	}
}